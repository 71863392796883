import React from "react";
// import Moment from "react-moment";

const LocationInfoBox = ({ info, onClick }) => {
  var date = new Date(info.date);

  return (
    <div className="location-info">
      <h5 className="close-info-box" onClick={onClick}>
        x
      </h5>
      <strong>{info.title}</strong>
      <p>{date.toString()}</p>
    </div>
  );
};

export default LocationInfoBox;
