import React from "react";

const Experience = () => {
  return (
    <div id="experience" className="experience">
      <div className="d-flex justify-content-center my-5">
        <h2>experience</h2>
      </div>

      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2021</h3>
            <p>
              <span>Dynamic Juice</span>. Engaging in building various projects,
              to practice new technologies in web development.
            </p>
          </div>
        </div>
        {/*---*/}

        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2021</h3>
            <p>
              <span>Coding Academy Fullstack Web Development Bootcamp</span>.
              HTML, CSS, algorithmic thinking, programming with JavaScript,
              SASS, jQuery, mongoDB, git, AJAX, working with APIs, REST server,
              Vue.js, Angular, React, social network integration,
              responsiveness, pixle-perfect design, and much more.
            </p>
          </div>
        </div>
        {/*---*/}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2015-2020</h3>
            <p>
              <span>Editor, Eilat Journalists Conference</span>. The premier
              annual event, organized by Tel-Aviv Journalists' Association,
              bringing together Israel's news media and political establishment.
              Editor of Tel Aviv Journalists’ Association website
              (journalism.co.il - currently offline).
            </p>
          </div>
        </div>
        {/*---*/}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2012-2015</h3>
            <p>
              <span>CEO, Mashov Group</span>. Organization of international
              exhibitions ("Fresh AgroMashov", "Cleantech") in the areas of
              agriculture, water technology, infrastructures and quality of the
              environment. Publishing of professional monthly magazines.
            </p>
          </div>
        </div>
        {/*---*/}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2009-2011</h3>
            <p>
              <span>Active Chairman, Tel Aviv Journalists’ Association</span>.
              Israel's largest organization for news media professionals,
              established in 1935.
            </p>
          </div>
        </div>
        {/*---*/}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2008-2010</h3>
            <p>
              <span>News Director, Radio Darom </span>. Regional station based
              out of Ashkelon and Beersheba with coverage over the southern half
              of Israel.
            </p>
          </div>
        </div>
        {/*---*/}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2005-2007</h3>
            <p>
              <span>Deputy Editor-In-Chief, HOT News – JCS</span>. Cable TV
              regional news – 5 daily newscasts throughout Israel.
            </p>
          </div>
        </div>
        {/*---*/}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2003-2005</h3>
            <p>
              <span>News Editor, HOT News – JCS</span>. Cable TV regional news -
              southern branch.
            </p>
          </div>
        </div>
        {/*---*/}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>1994-2003</h3>
            <p>
              <span>News Editor, Arutzey Zahav</span>. Cable TV regional news -
              southern branch.
            </p>
          </div>
        </div>
        {/*---*/}

        {/*---*/}

        {/*---*/}
      </div>
    </div>
  );
};

export default Experience;
