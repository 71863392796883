import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const serviceID = "service_ID";
  const templateID = "template_ID";
  const userID = "user_o4F5Z0TZVqcajxsdJSbGM";

  const onSubmit = (data, r) => {
    sendEmail(
      serviceID,
      templateID,
      {
        name: data.name,
        phone: data.phone,
        email: data.email,
        subject: data.subject,
        description: data.description,
      },
      userID
    );
    r.target.reset();
  };

  const sendEmail = (serviceID, templateID, variables, userID) => {
    emailjs
      .send(serviceID, templateID, variables, userID)
      .then(() => {
        setSuccessMessage("Form sent successfully!");
      })
      .catch((err) => console.error(`Something went wrong ${err}`));
  };

  return (
    <div id="contact" className="contacts">
      <div className="text-center">
        <h2>contact</h2>
        <p>
          Please fill out the form and I'll contact you as soon as possible.
        </p>
        <span className="success-message">{successMessage}</span>
      </div>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="form-container">
              {/*Name Input */}
              <input
                className="form-control"
                placeholder="Name"
                name="name"
                {...register("name", {
                  required: "ᐃ Please enter a name",
                })}
              />

              <span className="error-message">
                {errors.name && errors.name.message}
              </span>

              {/*Phone Input */}
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                name="phone"
                {...register("phone", {
                  required: "ᐃ Please add your phone number",
                })}
              />

              <span className="error-message">
                {errors.phone && errors.phone.message}
              </span>

              {/*Email Input */}
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="email"
                {...register("email", {
                  required: "ᐃ Please add your email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "ᐃ Please Enter a valid email address",
                  },
                })}
              />

              <span className="error-message">
                {errors.email && errors.email.message}
              </span>

              {/*Subject Input */}
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                name="subject"
                {...register("subject", {
                  required: "ᐃ OOPS, you forget to add the subject.",
                })}
              />

              <span className="error-message">
                {errors.subject && errors.subject.message}
              </span>

              {/*Message Input */}
              <textarea
                {...register("description")}
                type="text"
                className="form-control"
                placeholder="Message"
                name="description"
                {...register("description", {
                  required: "ᐃ Please describe your project...",
                })}
              ></textarea>

              <span className="error-message">
                {errors.description && errors.description.message}
              </span>

              <button className="btn-main-offer contact-btn" type="submit">
                Contact me
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
