import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Logo from "../logo.png";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className="footer bg-dark">
      <div className="container">
        <div className="row">
          <div className="container mb-5">
            <Link
              smooth={true}
              to="header"
              offset={-110}
              className="navbar-brand"
              href="#"
            >
              <img className="logo-footer" src={Logo} alt="LogoFooter" />
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p>It's always playtime @:</p>
            </div>
            <div className="d-flex">
              <p>6 Hartsit St. Omer, Israel</p>
            </div>

            <div className="d-flex">
              <a href="+972-5252-47-107">+972-5252-47-107</a>
            </div>
          </div>

          <hr />

          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="row">
              <div className="col">
                <Link
                  smooth={true}
                  to="header"
                  offset={-110}
                  className="navbar-brand"
                  href="#"
                  className="footer-nav"
                >
                  Home
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="about"
                  offset={-110}
                  className="nav-link"
                  href="#"
                  className="footer-nav"
                >
                  About
                </Link>
                <br />
                {/* <Link
                  smooth={true}
                  to="services"
                  offset={-110}
                  className="nav-link"
                  href="#"
                  className="footer-nav"
                >
                  Services
                </Link> */}
              </div>
              <div className="col">
                <Link
                  smooth={true}
                  to="experience"
                  offset={-110}
                  className="nav-link"
                  href="#"
                  className="footer-nav"
                >
                  Experience
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="portfolio"
                  offset={-110}
                  className="nav-link"
                  href="#"
                  className="footer-nav"
                >
                  Portfolio
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="video"
                  offset={-110}
                  className="nav-link"
                  href="#"
                  className="footer-nav"
                >
                  Video
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-lg-5 col-md-12 col-sm-6 align-items-center">
            <div className="social-share d-flex justify-content-center">
              <FacebookShareButton
                url={"https://www.dynamic-juice.com/"}
                qoute={"Fullstack Developer"}
                hashtag="#ReactJS"
              >
                <FacebookIcon className="mx-3" size={36} />
              </FacebookShareButton>

              <TwitterShareButton
                url={"https://www.dynamic-juice.com/"}
                qoute={"Fullstack Developer"}
                hashtag="#ReactJS"
              >
                <TwitterIcon className="mx-3" size={36} />
              </TwitterShareButton>

              <LinkedinShareButton
                url={"https://www.dynamic-juice.com/"}
                qoute={"Fullstack Developer"}
                hashtag="#ReactJS"
              >
                <LinkedinIcon className="mx-3" size={36} />
              </LinkedinShareButton>

              <WhatsappShareButton
                url={"https://www.dynamic-juice.com/"}
                qoute={"Fullstack Developer"}
                hashtag="#ReactJS"
              >
                <WhatsappIcon className="mx-3" size={36} />
              </WhatsappShareButton>
            </div>
            <p className="pt-3 text-center">
              &copy;
              {new Date().getFullYear()}&nbsp;DYNAMIC JUICE | All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
