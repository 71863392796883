import React, { useState, useEffect } from "react";
import logo from "../logo.png";
import { Link } from "react-scroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log("");
  }, [open]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-black fixed-top">
      <div className="container">
        <Link
          smooth={true}
          to="header"
          offset={-110}
          className="navbar-brand"
          href="#"
          onMouseDown={() => setOpen(!open)}
          onMouseUp={() => setOpen(!open)}
        >
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
        >
          <FontAwesomeIcon icon={faBars} style={{ color: "#fff" }} />
        </button>

        <div
          className={
            open ? "collapse navbar-collapse show" : "collapse navbar-collapse"
          }
          id="navbarSupportedContent"
        >
          <ul
            className="navbar-nav mr-auto"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            <li className="nav-item">
              <Link
                smooth={true}
                to="about"
                offset={-110}
                className="nav-link"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                onMouseDown={() => setOpen(!open)}
                onMouseUp={() => setOpen(!open)}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="climate"
                offset={-110}
                className="nav-link"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                onMouseDown={() => setOpen(!open)}
                onMouseUp={() => setOpen(!open)}
              >
                Climate
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                smooth={true}
                to="video"
                offset={-110}
                className="nav-link"
                href="#"
              >
                Video
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                smooth={true}
                to="experience"
                offset={-110}
                className="nav-link"
                href="#"
                onMouseDown={() => setOpen(!open)}
                onMouseUp={() => setOpen(!open)}
              >
                experience
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="portfolio"
                offset={-110}
                className="nav-link"
                href="#"
                onMouseDown={() => setOpen(!open)}
                onMouseUp={() => setOpen(!open)}
              >
                portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="gallery"
                offset={-110}
                className="nav-link"
                href="#"
                onMouseDown={() => setOpen(!open)}
                onMouseUp={() => setOpen(!open)}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="contact"
                offset={-110}
                className="nav-link"
                href="#"
                onMouseDown={() => setOpen(!open)}
                onMouseUp={() => setOpen(!open)}
              >
                contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
