import React from "react";

const Loader = () => {
  return (
    <div className="loader">
      <img
        src={
          "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635600218/362086.ba55a046.5000x5000o.ad5afbec6258_xuy4rz.gif"
        }
        alt="Loading..."
      />
      <h2>Fetching Data</h2>
    </div>
  );
};

export default Loader;
