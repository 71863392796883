import { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Particles from "react-particles-js";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Services from "./components/Services";
import Experience from "./components/Experience";
import Map from "./components/Map";
import Loader from "./components/Loader";
import MapHeader from "./components/MapHeader";

import ResponsivePlayer from "./components/ResponsivePlayer";

import StoriesSlider from "./components/StoriesSlider";

import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  const [eventData, setEventData] = useState([]);
  // const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const fetchEvents = async () => {
  //     setLoading(true);
  //     const res = await fetch(
  //       "https://eonet.sci.gsfc.nasa.gov/api/v2.1/events"
  //     );
  //     const { events } = await res.json();
  //     setEventData(events);
  //     // console.log(events);

  //     setLoading(false);
  //   };
  //   fetchEvents();
  // }, []);

  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <>
      <Particles
        className="particles-underwater"
        params={{
          particles: {
            number: {
              value: 200,
              density: {
                enable: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                speed: 4,
                size_min: 0.3,
              },
            },
            line_linked: {
              enable: false,
              shadow: {
                enable: true,
                color: "#07abf1",
                blur: 3,
              },
            },
            move: {
              speed: 1,
              direction: "top",
              random: true,
              out_mode: "out",
            },
          },
        }}
      />
      <Navbar />
      <Header />
      <AboutMe />

      <Services />
      <div>
        <MapHeader />
        {/* {!loading ? <Map eventData={eventData} /> : <Loader />} */}
      </div>

      <Portfolio />
      <ResponsivePlayer />

      <Experience />

      <StoriesSlider />

      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
