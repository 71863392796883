import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import useSound from "use-sound";
import woosh from "../sounds/woosh.mp3";

const Header = () => {
  const [playActive] = useSound(woosh, { volume: 0.25, playbackRate: 0.5 });

  const sliderImages = [
    // {
    //   src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1621927849/header3_a7peeg.jpg",
    // },

    // {
    //   src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1622011034/london_g681k6.jpg",
    // },
    // {
    //   src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1622548006/yoram_2_yswi3x.jpg",

    // },
    {
      src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1633426060/CyberSecurity_c3q8bd.gif",
    },
    // {
    //   src: "https://res.cloudinary.com/ddgevj2yp/video/upload/v1637079405/pexels-cottonbro-6203668_yv5cps.mp4",
    // },
    // {
    //   src: "https://res.cloudinary.com/ddgevj2yp/image/upload/e_grayscale/v1622540302/header3b_kqyfan.jpg",
    // },
  ];

  return (
    <div id="header" className="header-wraper">
      <Carousel
        images={sliderImages}
        shouldLazyLoad={true}
        isLoop={true}
        canAutoPlay={true}
        isAutoPlaying={true}
        autoPlayInterval={8000}
        hasLeftButton={false}
        hasRightButton={false}
        hasThumbnails={false}
        transitionSpeed={7}
        transitionDurationLimit={500}
        widgetsHasShadow={true}
        style={{ height: "100vh", width: "100%", marginBottom: "2rem" }}
      />

      <div className="main-info">
        <img
          src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1638713568/442008572_ARTIST_AVATAR_3D_400_ebgfhr.gif"
          alt="aa"
          className="face-gif"
        />
        <h1 clasName="text">it's always playtime</h1>

        <Link
          smooth={true}
          to="contact"
          offset={-110}
          href="#"
          className="btn-main-offer glow-on-hover"
          onMouseUp={playActive}
          style={{ fontSize: "1.1rem" }}
        >
          Let's build something great
        </Link>

        <Link
          smooth={true}
          to="about"
          offset={-110}
          href="#"
          // className="btn-main-offer"
          // onMouseUp={playActive}
        >
          <img
            src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1629619220/chevron-down-white_guhsy3.gif"
            alt="aa"
            className="scroll-gif"
          />
        </Link>
        {/* <p className="photo-byline">Photo: Avi Paz</p> */}
      </div>
      {/* </>
      )} */}
    </div>
  );
};

export default Header;
