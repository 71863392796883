import React from "react";
import ReactPlayer from "react-player";

const ResponsivePlayer = () => {
  return (
    <div id="video" className="video bg-dark">
      <h2 className="py-2">Video</h2>
      <div className="container mb-5">
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=2hpqWfn7qW8"
            width="100%"
            height="100%"
            loop={true}
            // controls={true}
          />
        </div>
        <p>
          Original Soundtrack: Yoram Poizner, PA-ONLY | Videographer: Emil
          Tevosov, Illumi | Editor: Igor Yablonovsky, Illumi | Makeup Artist :
          Carmela Megira | Actors: Ella Marcovich, Maor Solomon
        </p>
      </div>
    </div>
  );
};

export default ResponsivePlayer;
