import React, { useState, useEffect } from "react";
import Loader from "./Loader";
// import ClimateCloud from "./ClimateCloud";
// import ClimateCrush from "./ClimateCrush";
import RPlayer from "./Rplayer";

import "react-popupbox/dist/react-popupbox.css";

// var randomColor = require("randomcolor");

const MapHeader = () => {
  const [climateChangeArticles, setClimateChangeArticles] = useState("");

  // const [filteredItem, setFilteredItem] = useState({});
  const [moreFilteredItem, setMoreFilteredItem] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [shuffle, setShuffle] = useState(false);

  useEffect(() => {
    fetch("https://climate-change-news12.p.rapidapi.com/news", {
      method: "GET",
      headers: {
        "x-rapidapi-host": "climate-change-news12.p.rapidapi.com",
        "x-rapidapi-key": "c9e2373832msh3684d0434011171p1748c3jsnc67afc71588a",
      },
    })
      .then((res) => res.json())

      .then((data) =>
        setClimateChangeArticles(data.sort(() => Math.random() - 0.5))
      )

      .catch((err) => {
        console.error(err);
      });
  }, [shuffle]);

  return (
    <div className="map-header" id="climate">
      <div className="py-3"></div>
      <RPlayer />
      <p style={{ marginTop: "12px", marginBottom: "25px" }}>
        <button
          onClick={() =>
            window.open(
              "https://rapidapi.com/pazavi/api/climate-change-news12/",
              "_blank"
            )
          }
          class="btn btn-secondary"
          style={{
            backgroundColor: "#0E2A47",
            width: "320px",
            fontSize: "0.95rem",
            // color: "#f4f4f4",
            // textTransform: "uppercase",
          }}
          type="button"
        >
          Check out my climate news API
        </button>
      </p>
      <h3
        style={{
          maxWidth: "360px",
          margin: "auto",
          marginTop: "25px",
          zIndex: "200",
        }}
      >
        {/* Climate crisis isn't a prediction. It's happening */}
        Climate Change Headlines
      </h3>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 24 24"
        className="triangle-down"
      >
        <path fill="red" d="M24 22h-24l12-20z" />
      </svg> */}

      <div className="py-1"></div>
      <button
        className="shuffle-button"
        style={{ background: "none", border: "none" }}
        onClick={() => setShuffle(!shuffle)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
        >
          <path
            fill="#707B89"
            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-6 8.5h1c1.745 0 2.74.61 3.411 1.428-.205.326-.377.656-.527.969-.544-.822-1.318-1.397-2.884-1.397h-1v-1zm9 8.5v-1.5c-1.725 0-2.706-.609-3.369-1.425.206-.326.379-.655.529-.968.538.819 1.298 1.393 2.84 1.393v-1.5l3 2-3 2zm0-6v-1.5c-2.276 0-2.851 1.249-3.516 2.695-.713 1.549-1.52 3.305-4.484 3.305h-1v-1h1c2.323 0 2.903-1.262 3.575-2.723.707-1.536 1.508-3.277 4.425-3.277v-1.5l3 2-3 2z"
          />
        </svg>
        <br />
        {"  "}
        Five of {climateChangeArticles?.length} Headlines
      </button>

      <div className="py-1"></div>

      <div
        style={{
          maxWidth: "600px",
          // minHeight: "600px",
          display: "inline-block",
          textAlign: "left",
        }}
      >
        {!climateChangeArticles ? (
          <Loader />
        ) : (
          climateChangeArticles?.slice(0, 5).map((article) => (
            <a
              key={article.title}
              className="climate-link"
              key={article.index}
              href={article.url}
              style={{
                textDecoration: "none",
                display: "inline",
                textAlign: "left",
              }}
            >
              <p
                className="climate-headlines"
                data-aos="fade-up"
                data-aos-duration="2500"
                data-aos-once="false"
                data-aos-anchor-placement="center center"
                style={{
                  display: "block",
                  textAlign: "left",
                  fontSize: "1.2rem !important",
                  marginTop: "15px",
                }}
              >
                {article.title}{" "}
                <span style={{ fontSize: "0.9rem" }}> ({article.source}) </span>{" "}
                <hr style={{ color: "red" }} />
              </p>
            </a>
          ))
        )}
      </div>

      <div className="py-3"></div>
      {/* <p>
        <button
          onClick={() => setExpanded(!expanded)}
          class="btn btn-secondary"
          style={{
            backgroundColor: "#1a4e7900",
            width: "300px",
            fontSize: "0.95rem",
          }}
          type="button"
          data-toggle="collapse"
          data-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          {!expanded
            ? `View More ${climateChangeArticles?.length - 5} Headlines`
            : `Close More ${climateChangeArticles?.length - 5} Headlines`}
        </button>
      </p> */}
      {/* <div className="collapse" id="collapseExample">
        <div
          className="card card-body"
          style={{ maxWidth: "600px", margin: "auto", marginTop: "15px" }}
        >
          {!climateChangeArticles ? (
            <Loader />
          ) : (
            climateChangeArticles
              ?.slice(6, climateChangeArticles.length - 1)
              .map((article) => (
                <a
                  key={article.title}
                  draggable={false}
                  className="climate-link"
                  key={article.index}
                  href={article.url}
                  style={{
                    textDecoration: "none",
                    display: "inline",
                    textAlign: "left",
                  }}
                >
                  <p
                    draggable={false}
                    className={
                      moreFilteredItem
                        ? article.url === moreFilteredItem.url
                          ? "climate-headlines-white-more"
                          : "climate-headlines-more"
                        : "climate-headlines-more"
                    }
                    style={{
                      display: "block",
                      textAlign: "left",
                      fontSize: "1.2rem !important",
                      marginTop: "15px",
                    }}
                  >
                    {article.title}{" "}
                    <span style={{ fontSize: "0.9rem" }}>
                      {" "}
                      ({article.source}){" "}
                    </span>{" "}
                  </p>

                  <hr style={{ color: "red" }} />
                </a>
              ))
          )}
        </div>
      </div> */}

      <div className="py-1"></div>
    </div>
  );
};

export default MapHeader;
