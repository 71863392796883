import React, { useState } from "react";
// import Stories from "react-insta-stories";
import Carousel from "react-gallery-carousel";

import "react-gallery-carousel/dist/index.css";

//

const sliderImages = [
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1621927849/header3_a7peeg.jpg",
    caption:
      "Fishermen from the point of view of a giant cruise ship. Labadee, Haiti",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1622011034/london_g681k6.jpg",
    caption: "Street artist. London, Uk",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1622548006/yoram_2_yswi3x.jpg",
    caption:
      "I look at the floor and I see it needs sweeping. Caesarea Amphitheater, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1622539404/header3_1_oljg71.jpg",
    caption: "Bedouin tea ceremony. The Negev desert, Israel ",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1629738140/a_boy_and_hgis_camel_yn05yb.jpg",
    caption: "Boy on top of his Camel. The Negev desert, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1622539438/header5_tlimjf.jpg",
    caption: "Bell pepper grower In his greenhouse. The Arava desert, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1629737269/ako_vfdltt.jpg",
    caption: "Fresh-squeezed juice vendor. Acre marketplace, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1622613965/tokyo_zfmru7.jpg",
    caption: "Bystanders catching up on the news. Shibuya, Tokyo, Japan",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1623050824/Untitled_1_bconxf.jpg",
    caption: "Friends preparing for photo op. Cathedral of Barcelona, Spain",
    byline: "Avi Paz",
  },

  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1627629951/K12_dbr7sm.jpg",
    caption:
      "Israel's celebrated sculptor the late Dani Karavan. Beer-Sheva, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1629789813/L1_i86ibs.png",
    caption: "Mixologist measuring shots. Beer-Sheva, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1629736243/piccadily_efjja3.jpg",
    caption: "Homeward bound I wish I was. London, Uk",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1629786380/8_PAZ_6541_ojw26t.png",
    caption:
      "Shift manager at SodaStream's manufacturing facility. Hura, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1629789229/af_ahzz0o.jpg",
    caption: "Point shooting. New York, USA",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635338541/NB1_emf03b.png",
    caption: "Family enjoying the ocean. South beach, Miami, USA",
    byline: "Avi Paz",
  },

  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635329470/A11_inwhq0.jpg",
    caption: "Mother Nature. Kibbutz Ruhama, Israel",
    byline: "Avi Paz",
  },

  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635416489/aaa1_wwpimv.jpg",
    caption: "Natural Cereals. Borough Market, London, Uk",
    byline: "Avi Paz",
  },

  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635430358/14059463722_84a3baccc4_k_tlydsp.jpg",
    caption: "Potato sorting. Hevel Maon Enterprises, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635435856/15634117406_0fae71eb51_k_ni7bzt.jpg",
    caption:
      "Grocery Shop. Unrecognized Bedouin village, The Negev desert, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635436410/13283102293_81dc9a4e65_k_ec5r8i.jpg",
    caption: "Citrus grower. Moshav Maslul, Israel",
    byline: "Avi Paz",
  },

  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635434539/2175493329_933cb34487_o_lblnys.jpg",
    caption: "Butcher. kutaisi, Georgia",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635434838/179792908_aa01208c15_k_urdorm.jpg",
    caption: "Tired. Rural village near Chengdu, Sichuan, China",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635336633/2_ewtwso.jpg",
    caption: "Water therapy. Beit Halochem Beer Sheva, Israel",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635430153/15304382073_7845953c58_k_sjthpi.jpg",
    caption: "Speakers' Corner. Hyde Park, London, UK",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635431343/6017129541_c5d7b81c31_k_zdqxhh.jpg",
    caption: "Autonomous car. Disney World, Orlando, USA",
    byline: "Avi Paz",
  },
  {
    src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635434099/4912502128_9b7d9afe2d_k_h4r9fj.jpg",
    caption: "Schlafstunde. New York, USA",
    byline: "Avi Paz",
  },
  // {
  //   src: "https://res.cloudinary.com/ddgevj2yp/image/upload/v1635335372/10256538_10152328597257954_8713694460795830294_o_rkynpm.jpg",
  //   caption: "Planting tomatoes. Moshav Mivtahim, Israel",
  //   byline: "Avi Paz",
  // },
];

const StoriesSlider = () => {
  const [caption, setCaption] = useState(
    "Fishermen from the point of view of a giant cruise ship. Labadee, Haiti"
  );
  const [byline, setByline] = useState("Avi Paz");

  return (
    <div className="stories-background">
      <div id="gallery" className="stories-wrapper container">
        <h3
          style={{
            fontSize: "25px",
            textTransform: "uppercase",
            fontWeight: "300",
          }}
        >
          Capturing the Moments
        </h3>
        <p style={{ marginTop: "20px", fontWeight: "400", fontSize: "1.1rem" }}>
          Photographing people excites me. That split-second moment of pure
          freedom, the sense of pride in one's labour, the uninterrupted moment
          of passing thought — these are the snapshots of life memories that we
          can hold onto for forever.
        </p>

        <br />

        {/* <div className="py-3"></div> */}
        <Carousel
          images={sliderImages}
          isLoop={true}
          canAutoPlay={true}
          isAutoPlaying={true}
          autoPlayInterval={10000}
          hasIndexBoard={false}
          hasCaptions={true}
          hasThumbnails={true}
          transitionSpeed={7}
          transitionDurationLimit={500}
          onIndexChange={({ curIndex }) => {
            setCaption(" ");
            setCaption(sliderImages[curIndex].caption);
            setByline(sliderImages[curIndex].byline);
          }}
          style={{ height: "85vh", width: "100%", marginBottom: "1.5rem" }}
        />
        <p
          style={{
            color: "#696969",
            fontSize: "14px",
            marginBottom: "0",
            // textTransform: "uppercase",
            // marginTop: "-25px",
            // marginLeft: "10px",
          }}
        >
          Photos: {byline}
        </p>
        <p
          style={{
            color: "#07abf1",
            fontSize: "1rem",
            // fontWeight: "300",
            // textTransform: "uppercase",
            marginTop: "5px",
            minHeight: "45px",
            marginBottom: "0px",
          }}
        >
          {caption}
        </p>
      </div>

      <div className="py-5"></div>
    </div>
  );
};

export default StoriesSlider;
