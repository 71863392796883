import React from "react";
import ReactPlayer from "react-player";

const RPlayer = () => {
  return (
    <div className="container mb-5">
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://res.cloudinary.com/ddgevj2yp/video/upload/v1638806563/Nothing_Else_Matters_Made_with_FlexClip_ivbjpg.mp4"
          width="100%"
          height="100%"
          loop={true}
          playing={true}
          volume={0}
          controls={true}
          playsinline={true}
        />
      </div>
      <p style={{ marginTop: "20px", textAlign: "left", fontSize: "14px" }}>
        Video: Mikhail Nilov, Wolfgang Langer, Max Fischer, Ross Harrison |
        Pexels
      </p>
    </div>
  );
};

export default RPlayer;
