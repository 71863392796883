import React from "react";
import proj1 from "../images/proj1.png";
import proj2 from "../images/proj2.png";
import proj3 from "../images/proj3.png";
import proj4 from "../images/proj4.png";
import proj5 from "../images/proj5.png";
import proj9 from "../images/proj9.png";

import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";

const Portfolio = () => {
  // proj1
  const openPopupboxProj1 = () => {
    const content = (
      <>
        <img className="portfolio-image-popupbox" src={proj1} alt="proj1" />
        <p>
          eCommerce application with the MERN stack : Full featured shopping
          cart, Product reviews and ratings, Top products carousel, Product
          pagination, Product search feature, User profile with orders, Admin
          product management, Admin user management, Admin Order details page,
          Mark orders as delivered option, Checkout process (shipping, payment
          method, etc) and PayPal / credit card integration
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://shopaz.herokuapp.com/")}
        >
          SHOPAZ - Photo Video Digital Cameras
        </a>{" "}
        | <b>Code:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://github.com/pazavi/shopaz")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj1 = {
    titleBar: {
      enable: true,
      text: "one",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  // proj2
  const openPopupboxProj2 = () => {
    const content = (
      <>
        <img className="portfolio-image-popupbox" src={proj9} alt="proj9" />
        <p>
          Travel Companion Application. Google Maps API & Google Places API,
          fetching API data based on the location, data filtering, Material Ui.
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://aroundu.netlify.app/")}
        >
          aroundU - Travel Advisor App
        </a>{" "}
        | <b>Code:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://github.com/pazavi/tadvisor")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj2 = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  // proj3
  const openPopupboxProj3 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1641305373/thehump_gywp2q.png"
          alt="proj3"
        />
        <p>
          Full Stack Social Media App With Google Authentication, create, edit,
          delete and save posts, like and comment on other people's posts,
          search and filter images.
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://thehump.netlify.app/")}
        >
          The Hump - Share and find inspiration
        </a>{" "}
        | <b>Code:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() =>
            window.open("https://github.com/pazavi/thehump_frontend")
          }
        >
          Frontend{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() =>
            window.open("https://github.com/pazavi/thehump_backend")
          }
        >
          {" "}
          Backend{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj3 = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  const openPopupboxProj4 = () => {
    const content = (
      <>
        <img className="portfolio-image-popupbox" src={proj4} alt="proj4" />
        <p>
          Discover some of the brutalist architectural gems of Be'er-Sheva.
          React JS project, completely responsive. I have been using React
          hooks, styled components, and React Router to create this web design.
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://b7brutalism.netlify.app/")}
        >
          B7BRUTALISM - EXPLORE THE BRUTALIST GEMS OF BE'ER SHEVA
        </a>{" "}
        | <b>Code:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://github.com/pazavi/B7BRUTALISM")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj4 = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };
  //proj5
  const openPopupboxProj5 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1638188157/projblog_ixkysv.png"
          alt="proj5"
        />
        <p>
          Blog Application. Built with NextJS, Tailwind CSS, GraphQL and
          GraphCMS. With featured and recent posts, categories. full markdown
          articles, author information and comments.
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://dyju.vercel.app/")}
        >
          DYJU Blog
        </a>{" "}
        | <b>Code:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://github.com/pazavi/DYJU_Blog")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj5 = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  // proj6
  const openPopupboxProj9 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1633941778/cryp14_dgp1jq.png"
          alt="proj2"
        />
        <p>
          Cryptocurrency App, using React, Redux Toolkit for State Management,
          Ant Design for UI and fetching data from multiple sources using
          RapidAPI.
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://cryptomatrix.netlify.app/")}
        >
          CRYPTO MATRIX - Global Cryptocurrencies Stats & News
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj9 = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };
  // proj10
  const openPopupboxProj10 = () => {
    const content = (
      <>
        <img className="portfolio-image-popupbox" src={proj3} alt="proj3" />
        <p>
          Masterclass events application. The tech stack is React/NextJS for
          frontend & Strapi CMS for backend; Authentication with JWT,
          Authorization and access control, Storing HttpOnly Cookies on the
          server, Pagination, search filters, Image uploading with Cloudinary
          integration, Deploying to Vercel and Heroku.
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://masterclasstok.vercel.app/")}
        >
          TOK - find free masterclass events
        </a>{" "}
        | <b>Code:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() =>
            window.open("https://github.com/pazavi/masterclass-frontend")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj10 = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };
  const openPopupboxProj11 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1641403355/proj11_c88b4v.png"
          alt="proj11"
        />
        <p>
          Optimise your multiple deliveries. Using TomTom Map SDK for Web, to
          show us the best route for you as the delivery driver, to drop off all
          deliveries based on time.
        </p>
        <b>Demo:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() => window.open("https://mapaz.netlify.app/")}
        >
          Matrix Routing
          {/* </a>{" "}
        | <b>Code:</b>{" "}
        <a
          style={{ color: "#07abf1" }}
          className="hyper-link"
          onClick={() =>
            window.open("https://github.com/pazavi/masterclass-frontend")
          }
        >
          Github */}
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupboxConfigProj11 = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  return (
    <div id="portfolio" className="portfolio-wrapper">
      <div className="py-1"></div>

      <h2 className="py-3">portfolio</h2>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj1}>
              <div className="image-wrapper">
                <img className="portfolio-image" src={proj1} alt="proj1" />
                <div className="project-text">
                  Full featured shopping cart, Product reviews and ratings, Top
                  products carousel, Product pagination, Product search feature,
                  User profile with orders, Admin product & user management,
                  Admin Order details page, Mark orders as delivered option,
                  Checkout process and PayPal / credit card integration
                </div>
              </div>
              <div className="project-caption">
                SHOPAZ - eCommerce application
              </div>
            </div>
          </div>
          {/* - */}

          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj3}>
              <div className="image-wrapper">
                <img
                  className="portfolio-image"
                  src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1641305373/thehump_gywp2q.png"
                  alt="proj3"
                />
                <div className="project-text">
                  Full Stack Social Media App With Google Authentication,
                  create, edit, delete and save posts, like and comment on other
                  people's posts, search and filter images.
                </div>
              </div>
              <div className="project-caption">
                The Hump - Share and find inspiration
              </div>
            </div>
          </div>
          {/* - */}
          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj9}>
              <div className="image-wrapper">
                <img
                  className="portfolio-image"
                  src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1633941778/cryp14_dgp1jq.png"
                  alt="proj2"
                />
                <div className="project-text">
                  Cryptocurrency App, using React, Redux Toolkit for State
                  Management, Ant Design for UI and fetching data from multiple
                  sources using RapidAPI.
                </div>
              </div>

              <div className="project-caption">
                CRYPTO MATRIX - Cryptocurrencies Stats
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj2}>
              <div className="image-wrapper">
                <img className="portfolio-image" src={proj9} alt="proj9" />
                <div className="project-text">
                  Travel Companion Application. Google Maps API & Google Places
                  API, fetching API data based on the location, data filtering,
                  Material Ui.
                </div>
              </div>
              <div className="project-caption">
                aroundU - Travel Advisor App
              </div>
            </div>
          </div>
          {/* - */}
          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj4}>
              <div className="image-wrapper">
                <img className="portfolio-image" src={proj4} alt="proj4" />
                <div className="project-text">
                  Discover some of the brutalist architectural gems of
                  Be'er-Sheva. React JS project, completely responsive. I have
                  been using React hooks, styled components, and React Router to
                  create this web design.
                </div>
              </div>
              <div className="project-caption">
                B7BRUTALISM - the brutalist gems of B7
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj5}>
              <div className="image-wrapper">
                <img
                  className="portfolio-image"
                  src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1638188157/projblog_ixkysv.png"
                  alt="proj5"
                />
                <div className="project-text">
                  Blog Application. Built with NextJS, Tailwind CSS, GraphQL and
                  GraphCMS. With featured and recent posts, categories. full
                  markdown articles, author information and comments.
                </div>
              </div>
              <div className="project-caption">Blog Application</div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj10}>
              <div className="image-wrapper">
                <img className="portfolio-image" src={proj3} alt="proj10" />
                <div className="project-text">
                  Masterclass events application. The tech stack is React/NextJS
                  for frontend & Strapi CMS for backend; Authentication with
                  JWT, Authorization and access control, Storing HttpOnly
                  Cookies on the server, Pagination, search filters, Image
                  uploading with Cloudinary integration, Deploying to Vercel and
                  Heroku.
                </div>
              </div>
              <div className="project-caption">TOK - Masterclass events</div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="box shadow" onClick={openPopupboxProj11}>
              <div className="image-wrapper">
                <img
                  className="portfolio-image"
                  src="https://res.cloudinary.com/ddgevj2yp/image/upload/v1641403355/proj11_c88b4v.png"
                  alt="proj11"
                />
                <div className="project-text">
                  Optimise your multiple deliveries. Using TomTom Map SDK for
                  Web, to show the best route for you as the delivery driver, to
                  drop off all deliveries based on time.
                </div>
              </div>
              <div className="project-caption">Matrix Routing</div>
            </div>
          </div>
        </div>
      </div>
      <PopupboxContainer {...popupboxConfigProj1} />
      <PopupboxContainer {...popupboxConfigProj2} />
      <PopupboxContainer {...popupboxConfigProj3} />
      <PopupboxContainer {...popupboxConfigProj4} />
      <PopupboxContainer {...popupboxConfigProj5} />
      <PopupboxContainer {...popupboxConfigProj10} />
      <PopupboxContainer {...popupboxConfigProj11} />
      <div className="py-3"></div>
    </div>
  );
};

export default Portfolio;
