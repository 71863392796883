import React from "react";
import TestimonialsCarousel from "./TestimonialsCarousel";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <h2>testimonials</h2>
      <div className="container">
        <div className="testimonials-content"></div>
      </div>
      <TestimonialsCarousel />
    </div>
  );
};

export default Testimonials;
