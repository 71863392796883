import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import avatar1 from "../images/avatars/avatar1.jpeg";
import avatar2 from "../images/avatars/avatar2.png";
import avatar3 from "../images/avatars/avatar3.jpeg";
import avatar4 from "../images/avatars/avatar4.jpeg";

const TestimonialsCarousel = () => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={5000}
      // centerMode={true}
      // centerSlidePercentage={150}
    >
      <>
        <img
          src={
            "https://res.cloudinary.com/ddgevj2yp/image/upload/v1629812025/roy1_okjwpd.png"
          }
          alt="RoyKatz"
        />
        <div className="myCarousel" style={{ minWidth: "300px" }}>
          <h3>Roy Katz</h3>
          <h5
            style={{
              color: "#07abf1",
              fontWeight: "300",
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            VP of Content, Radio Tel-Aviv
          </h5>

          <p>
            ‏ Avi is a witty, media professional with extensive experience from
            both sides of the production and design table. A true friend and
            forever mentor, Avi is an asset for any organization.
          </p>
        </div>
      </>
      <>
        <img src={avatar2} alt="Gev" />
        <div className="myCarousel" style={{ minWidth: "300px" }}>
          <h3>Gev Miron</h3>
          <h5
            style={{
              color: "#07abf1",
              fontWeight: "300",
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            Partner, Creative Director at MVH CREATIVEWORKS
          </h5>
          <p>
            ‏ Avi’s prolific career as a journalist and passion for stories make
            him a key player in the media world, a real pro who is always
            striving to make an impact. He is a true leader who makes everyone
            on his team better.
          </p>
        </div>
      </>
      {/*
      <>
        <img src={avatar3} alt="avatar3" />
        <div className="myCarousel" style={{ minWidth: "300px" }}>
          <h3>Malan Alankara</h3>
          <h5
            style={{
              color: "#07abf1",
              fontWeight: "300",
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            VP of marketing, Bla inc
          </h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi
            incidunt inventore hic fugiat autem, explicabo similique vero omnis
            culpa dicta.
          </p>
        </div>
      </>
      <>
        <img src={avatar4} alt="avatar4 " />
        <div className="myCarousel" style={{ minWidth: "300px" }}>
          <h3>Alex Lee</h3>
          <h5
            style={{
              color: "#07abf1",
              fontWeight: "300",
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            VP of marketing, Bla inc
          </h5>
          <p style={{ minHeight: "250px", maxHeight: "300px" }}>
            ‏ Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi
            incidunt inventore hic fugiat autem, explicabo similique vero omnis
            culpa dicta.
          </p>
        </div>
      </> */}
    </Carousel>
  );
};

export default TestimonialsCarousel;
